@font-face
    font-family: 'Plantin Infant'
    src: url('/assets/fonts/Plantin-Infant.otf') format('opentype')

@font-face
    font-family: 'MaisonNeue Book'
    src: url('/assets/fonts/MaisonNeue-Book.otf') format('opentype')

@font-face
    font-family: 'Sunrise Regular'
    src: url('/assets/fonts/Sunrise-Regular.otf') format('opentype')
