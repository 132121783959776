$color-black: #000000
$color-dark-gray: #404040
$color-white: #FFFFFF
$color-dark-blue-0: #04073F
$color-dark-blue-1: #33378D
$color-dark-blue-2: #323296
$color-red: #FF3C46
$color-orange: #FF854E
$color-green: #14A058
$color-yellow: #FBD545

$color-font: $color-black
$color-link: $color-dark-blue-0
$color-link-active: $color-dark-blue-2

$color-button: $color-dark-blue-0
$color-button-active: $color-dark-blue-2

$color-placeholder: #9c9c9c
