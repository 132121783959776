@import 'assets/styles/reset'
@import 'assets/styles/colors'
@import 'assets/styles/fonts'
@import 'assets/styles/animations'

@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel'
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default'
// -----------------------------------------------------------------------------
// Global template
// -----------------------------------------------------------------------------

*
    box-sizing: border-box

html
    font-size: 10px
    line-height: 10px

body
    font-family: 'MaisonNeue Book', Arial, sans-serif
    font-size: 1.25rem
    font-weight: 400
    line-height: 2rem

a
    border-bottom: 1px solid $color-dark-blue-0
    color: $color-link
    outline: none
    text-decoration: none
    cursor: pointer

a.no-border
    border-bottom-width: 0

a:active, a:hover
    color: $color-link-active

a.internal
    border-bottom-width: 0
    display: flex
    align-items: center

    &-arrow
        padding-left: 0.5rem
        background-image: url('assets/images/right-arrow.svg')
        background-repeat: no-repeat
        background-position: right center
        background-size: 1.3rem

    span
        margin-right: 2rem
p
    margin-bottom: 1.25rem

input::placeholder
    color: $color-placeholder

textarea
    resize: none
    font: inherit
    font-size: inherit
    width: 100%
    padding: 1.5rem
    border-radius: 0
    border-color: black
    height: 20rem

textarea::placeholder
    color: $color-placeholder

.pointer-hover
    &:hover
        cursor: pointer

.small
    font-size: 1.2rem

.bold
    font-weight: bold

.small-note
    font-size: 0.8rem
    color: $color-dark-blue-2
    font-weight: bold

    &.small-note--errored
        color: $color-red

.guttered,
.guttered-h
    padding-left: 2.2rem
    padding-right: 2.2rem

.guttered,
.guttered-v
    padding-top: 2.2rem
    padding-bottom: 2.2rem

.title-h1,
.title-h2
    font-family: 'Plantin Infant', 'Times New Roman', serif
    font-weight: 400
    line-height: 1em
.title-h1
    font-size: 2.9rem
.title-h2
    font-size: 2.2rem

.title-row
    display: flex
    align-items: center
    justify-content: space-between
    padding-top: 1rem
    padding-bottom: 1rem

    .title-row__left-content
        display: flex
        gap: 2rem
        align-items: center

    .title-row__right-content
        display: flex
        gap: 1rem
        align-items: center

.hide-on-desktop
    display: block
.hide-on-mobile
    display: none

.separator
    display: block
    width: 100%
    height: 1px
    border-bottom: 1px solid $color-font
.separator--subsection
    margin-bottom: 3rem

.display-none
    display: none !important

.hidden
    visibility: hidden !important
    opacity: 0 !important

.delete-button
    color: $color-black
    font-size: 40px
    font-weight: bold
    transition: 0.3s

    &:hover
        color: $color-dark-gray
        text-decoration: none
        cursor: pointer

    &.delete-button__light
        color: $color-white

        &:hover
            color: #bbb

@media screen and (min-width: 640px) and (max-width: 1329px)
    html
        font-size: 16px

@media screen and (min-width: 1330px)
    html
        font-size: calc(10px + 0.4vw - 4px)

    .hide-on-desktop
        display: none
    .hide-on-mobile
        display: block

// -----------------------------------------------------------------------------
// Header & Navigation bar
// -----------------------------------------------------------------------------

.navbar-wrapper
    position: sticky
    top: 0
    left: 0
    width: 100%
    background-color: white
    z-index: 99

.navbar
    position: relative
    display: flex
    align-items: center
    height: 4.6875rem
    margin-left: 2rem
    margin-right: 2rem
    border-bottom: 1px solid black

    .navbar__logo-wrapper
        position: absolute
        display: flex
        align-items: center
        justify-content: center
        width: 100%

        .navbar__logo
            display: flex
            align-items: center
            justify-content: center
            z-index: 3

            &:hover
                cursor: pointer

            .logo__sun, .logo__icon, .logo__rise
                height: 2.1875rem
                background-size: contain
                background-position: left center
                background-repeat: no-repeat

            .logo__sun
                width: 4.125rem
                background-image: url('assets/images/navbar-logo/img_logo_sunrise_black_part_1.svg')

            .logo__icon
                width: 1.9375rem
                transform-origin: 50% 57.14% 0
                background-image: url('assets/images/navbar-logo/img_logo_sunrise_black_part_2.svg')

            .logo__rise
                width: 4.0625rem
                background-image: url('assets/images/navbar-logo/img_logo_sunrise_black_part_3.svg')


    .navbar__buttons-wrapper
        position: absolute

        .link
            &:hover
                color: #33378D
                cursor: pointer

    .account-link-wrapper
        display: flex
        align-items: center

        .warning-wrapper__icon
            width: 1.3rem
            height: 1.3rem
            vertical-align: middle

            + .link
                margin-left: 0.625rem

    .lang
        text-transform: uppercase

@media screen and (min-width: 1330px)
    .navbar__buttons-wrapper
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        display: flex
        align-items: center
        gap: 1.5rem
        width: 100%
        height: 100%

        .navbar__links
            display: flex
            align-items: center
            justify-content: center
            height: 40%

            &:last-child
                margin-left: auto

            .link
                margin-left: 1.5rem
                color: #00053c
                border-bottom: 1px solid transparent
                transition: all ease 250ms

                &:hover
                    border-bottom-color: #00053c

                    .link__sub-links
                        opacity: 100
                        visibility: visible
                        transform: translateY(0)
                        transition-delay: 0s

                &:first-child
                    margin-left: 0

            .link.link--active
                border-bottom-color: #00053c

            .link__sub-links
                position: absolute
                top: 4.5rem
                left: -1rem
                padding: 0.5rem 1rem
                background-color: white
                border-radius: 0.5rem
                box-shadow: 0.2rem 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.2)
                transform: translateY(1rem)
                opacity: 0
                visibility: hidden
                transition: all 500ms ease
                transition-delay: 0.5s

                &:hover
                    opacity: 100
                    visibility: visible
                    transform: translateY(0)
                    transition-delay: 0s

                .sub-link
                    color: #00053c
                    border-bottom: 1px solid transparent
                    transition: all ease 250ms
                    text-transform: uppercase

                    &:hover
                        border-bottom-color: #00053c
                        cursor: pointer
        .lang-link
            &::after
                content: ""
                width: 1px
                height: 100%
                background-color: #00053c
                margin-left: 1.5rem

@media screen and (max-width: 1329px)
    .navbar
        justify-content: center

        &.connected-navbar
            .navbar__links
                &:nth-child(1)
                    order: 3

                &:nth-child(2)
                    order: 1

                &:nth-child(3)
                    order: 2

    .navbar__burger
        position: absolute
        left: 0
        display: flex
        flex-direction: column
        justify-content: space-between
        width: 1.75rem
        height: 1.5rem
        margin: 0
        padding: 0
        background-color: transparent
        border: none
        border-radius: 0
        outline: none
        z-index: 9

        &:hover
            cursor: pointer

        &.navbar__burger--active
            .burger__line
                &:nth-child(1)
                    width: 110%
                    transform: rotate(45deg)
                &:nth-child(2)
                    opacity: 0
                &:nth-child(3)
                    width: 110%
                    transform: rotate(-45deg)

        .burger__line
            display: block
            width: 100%
            height: 1px
            background-color: #00053c
            transition: all ease 0.5s
            transform-origin: 0 0

    .navbar__buttons-wrapper
        display: flex
        flex-direction: column
        top: 4.6875rem
        left: -1.5rem
        width: calc(100% + 3rem)
        height: 0
        padding-left: 1.5rem
        padding-right: 1.5rem
        background-color: white
        overflow-y: auto
        transition: all ease 0.5s

        &.navbar__buttons-wrapper--active
            height: calc(100vh - 4.6875rem)

        .link
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            text-align: center
            border-bottom: 1px solid #00053c
            padding-top: 1.5rem
            padding-bottom: 1.5rem
            color: #00053c

            .link__sub-links
                display: none
                width: 100%
                padding-top: 0.5rem
                padding-bottom: 0.5rem

                &.link__sub-links--active
                    display: block

    .account-link-wrapper
        display: flex
        align-items: center
        justify-content: center
        border-bottom: 1px solid #00053c

        .link
            border-bottom: none

        .warning-wrapper__icon
            width: 1.3rem
            height: 1.3rem
            vertical-align: middle

            + .link
                margin-left: 0.625rem


// -----------------------------------------------------------------------------
// Router
// -----------------------------------------------------------------------------

.router
    min-height: calc(100vh - 22rem - 4.6875rem)

@media screen and (min-width: 1330px)
    .router
        min-height: calc(100vh - 6rem - 4.6875rem - 2px)


// -----------------------------------------------------------------------------
// Footer
// -----------------------------------------------------------------------------

.footer
    display: block
    height: 22rem
    width: 100%
    padding-left: 2rem
    padding-right: 2rem

.footer .footer-items
    padding-top: 2rem
    padding-bottom: 2rem

.footer .footer-item
    display: block
    width: 100%
    height: 3rem
    text-align: center
    border-bottom-width: 0
    color: $color-font

.footer a.footer-item:active,
.footer a.footer-item:hover
    color: $color-dark-blue-2

@media screen and (min-width: 1330px)
    .footer
        height: 6rem
    .footer .footer-items
        display: flex
        height: 100%
        justify-content: flex-end
        padding-top: 0
        padding-bottom: 0
        align-items: center
    .footer .footer-items .footer-item
        width: auto
        height: auto
        margin-left: 2rem

// -----------------------------------------------------------------------------
// Main container
// -----------------------------------------------------------------------------

.row
    width: 100%
.row.row--guttered,
.row.row--guttered-h
    padding-left: 2rem
    padding-right: 2rem
.row.row--guttered,
.row.row--guttered-v
    padding-top: 2rem
    padding-bottom: 2rem
.row.row--guttered-v2
    padding-top: 4rem
    padding-bottom: 4rem

.row.row--flex
    display: flex
    justify-content: space-between

@media screen and (min-width: 60rem)
    .row.row--tiny
        width: 60rem
        margin: 0 auto

@media screen and (min-width: 1330px)
    .row.row--smaller
        width: 80rem
        margin: 0 auto

    .row.row--guttered.content,
    .row.row--guttered.row--guttered-v.content
        padding-top: 5rem
        padding-bottom: 5rem

// -----------------------------------------------------------------------------
// Titles
// -----------------------------------------------------------------------------

.title-h1,
.title-h2,
.title-h3
    font-family: 'Plantin Infant'
    font-weight: 400
    line-height: 1em
.title-h1
    font-size: 2.9rem
.title-h2
    font-size: 2.2rem
.title-h3
    font-size: 1.8rem

// -----------------------------------------------------------------------------
// Buttons
// -----------------------------------------------------------------------------

.button-like
    display: inline-block
    margin: 1.5rem auto
    font-size: 0.9em
    text-align: center

    &:hover + .button-like--tooltip
        opacity: 1
        visibility: visible
        transition-delay: 0s

.button-like.button-like--no-margin
    margin: 0

.button-like.button-like--is-loading,
.button-like.button-like--is-loading:active,
.button-like.button-like--is-loading:hover
    color: $color-link-active
    opacity: 0.9
    cursor: wait

.button-like.button-like--disabled,
.button-like.button-like--disabled:active,
.button-like.button-like--disabled:hover
    color: $color-link-active
    opacity: 0.9
    cursor: default
    padding-bottom: 0
    border-bottom: none

.button-like--tooltip
    position: absolute
    top: -50%
    left: 60%
    right: 10%
    transform: translateY(1.5rem)
    opacity: 0
    visibility: hidden
    padding: 0.5rem
    background-color: white
    border-radius: 0.5rem
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px
    color: #04073F
    font-size: 0.9em
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out
    transition-delay: 0.5s

@-webkit-keyframes rotating /* Safari and Chrome */
    from
        -webkit-transform: rotate(0deg)
        -o-transform: rotate(0deg)
        transform: rotate(0deg)

    to
        -webkit-transform: rotate(360deg)
        -o-transform: rotate(360deg)
        transform: rotate(360deg)

@keyframes rotating
    from
        -ms-transform: rotate(0deg)
        -moz-transform: rotate(0deg)
        -webkit-transform: rotate(0deg)
        -o-transform: rotate(0deg)
        transform: rotate(0deg)

    to
        -ms-transform: rotate(360deg)
        -moz-transform: rotate(360deg)
        -webkit-transform: rotate(360deg)
        -o-transform: rotate(360deg)
        transform: rotate(360deg)

.logo_loading
    -webkit-animation: rotating 1.5s linear infinite
    -moz-animation: rotating 1.5s linear infinite
    -ms-animation: rotating 1.5s linear infinite
    -o-animation: rotating 1.5s linear infinite
    animation: rotating 1.5s linear infinite
    height: 70%
    width: auto
    margin-top: 2px

// -----------------------------------------------------------------------------
// Fields & Inputs
// -----------------------------------------------------------------------------

.field-readonly
    font-size: 0.9em
    color: $color-dark-gray

// Field-container
.field-readonly,
.field-container,
.validatable-container,
.label,
.field,
.error-text,
.hint-text
    width: 100%
    display: block

.field-container
    margin-bottom: 2rem

.field-container .label,
.field-container .field,
.field-container .error-text,
.field-container .hint-text
    margin-bottom: 1rem

.field-container .field
    padding: 1.5rem
    font-family: inherit
    font-size: 0.9em
    border: 1px solid $color-font

// Two fields per row
@media screen and (min-width: 60rem)
    .two-field-container-per-row
        display: flex
    .two-field-container-per-row > .field-container,
    .two-field-container-per-row > .validatable-container
        width: 50%
    .two-field-container-per-row > .field-container:nth-child(1),
    .two-field-container-per-row > .validatable-container:nth-child(1)
        padding-right: 1rem
    .two-field-container-per-row > .field-container:nth-child(2),
    .two-field-container-per-row > .validatable-container:nth-child(2)
        padding-left: 1rem

// Three fields per row
.three-field-container-per-row
    display: flex
    flex-direction: column
.three-field-container-per-row .field-container
    width: 100%
    margin-bottom: 1rem

@media screen and (min-width: 60rem)
    .three-field-container-per-row
        flex-direction: row
    .three-field-container-per-row .field-container
        width: 33.333333%
    .three-field-container-per-row .field-container:first-child
        padding-right: 1rem
    .three-field-container-per-row .field-container:last-child
        padding-left: 1rem

.field-container.required .label::after
    content: '*'
    margin-left: 0.5rem
    color: $color-red

.field-container--checkbox.disabled
    opacity: 0.25
    cursor: not-allowed

    .label, input, .checkmark
        cursor: not-allowed !important

    .checkmark
        background-color: $color-placeholder

// Errors
.field-container .error-text
    display: none
    color: $color-red
    font-size: 1rem
    line-height: 1.5rem

.field-container.field-container--errored .label
    color: $color-red

.field-container.field-container--errored .field,
.field-container.field-container--errored .separate-dial-code,
.field-container.field-container--errored .date-picker__input
    border-color: $color-red

.field-container.field-container--errored .error-text
    display: block

// Hint
.field-container .hint-text
    font-size: 1rem
    line-height: 1.5rem

// Checkbox input
.field-container.field-container--checkbox
    position: relative
    padding-left: 2.5rem

.field-container.field-container--checkbox .error-text
    margin-left: -2.5rem

.field-container.field-container--checkbox .hint-text
    margin-left: -2.5rem

.field-container.field-container--checkbox input
    position: absolute
    top: 0.05rem
    left: 0
    width: 1.75rem
    height: 1.75rem
    opacity: 0
    cursor: pointer
    z-index: 1

.field-container.field-container--checkbox .checkmark
    position: absolute
    top: 0.05rem
    left: 0
    width: 1.75rem
    height: 1.75rem
    border: 1px solid $color-font

.field-container.field-container--checkbox:hover:not(.disabled) input ~ .checkmark
    background-color: rgba($color-dark-blue-2, 0.125)

.field-container.field-container--checkbox input:checked ~ .checkmark
    background-color: $color-dark-blue-0 !important
    border-color: $color-dark-blue-0 !important

.field-container.field-container--checkbox .checkmark:after
    content: ""
    position: absolute
    display: none

.field-container.field-container--checkbox input:checked ~ .checkmark:after
    display: block

.field-container.field-container--checkbox .checkmark:after
    top: 0.1rem
    left: 0.5rem
    width: 0.5rem
    height: 1rem
    border: solid $color-white
    border-width: 0 1px 1px 0
    transform: rotate(45deg)

// Password input
.field-password-container
    position: relative
    width: 100% !important

    & > .password-input
        padding-right: 7rem !important

.password-visibility-button
    position: absolute
    padding: 1.4rem
    top: 0
    right: 0
    cursor: pointer

.password-visibility-text
    font-size: 0.9em

    &::selection
        background: none

.two-buttons-container
    display: flex

    > *:not(:last-child)
        margin-right: 2rem

// -----------------------------------------------------------------------------
// Lists
// -----------------------------------------------------------------------------

.list-row
    position: relative
    display: flex
    padding-top: 1.5rem
    padding-bottom: 1.5rem
    border-bottom: 1px solid $color-font

.list-row.list-row--no-border
    border-bottom-width: 0

.list-row.list-row--no-padding
    padding-top: 0
    padding-bottom: 0

.list-row.list-row--no-top-padding
    padding-top: 0

.list-row.list-row--title
    font-size: 1.6rem
    font-weight: bold

.list-row.list-row--header
    font-weight: bold
    align-items: flex-end

.list-row.list-row--clickable:hover
    background-color: #f4f4f4
    cursor: pointer

.list-row.list-row--warning
    border-bottom: 0px
    cursor: pointer
    background-color: #FF854E
    color: white
    margin-bottom: 3rem
    border-radius: 0.8rem

    &:first-child
        padding-left: 1rem

.list-row.list-row--disabled,
.list-row.list-row--clickable.list-row--disabled:hover
    background-color: transparent
    color: #aaa
    cursor: default

.list-row.list-row--not-clickable,
.list-row.list-row--not-clickable.list-row--not-clickable:hover
    background-color: #dedede
    cursor: default

.list-row .col
    width: 100%
    display: flex
    align-items: center
.list-row .col--smaller
    width: 50%
.list-row .col--longer
    width: 140%
.list-row .col--tiny
    width: 2rem
.list-row .col.col--see-more
    justify-content: flex-end
.list-row .col.col--clickable
    cursor: pointer
.list-row .col.col--subtitle
    div:nth-child(1)
        display: block

        div:nth-child(2)
            font-weight: lighter
            font-size: 0.7em

.notification-dot
    display: inline-block
    width: 0.9rem
    height: 0.9rem
    background-color: $color-red
    border-radius: 1rem

.list-row .notification-dot
    position: absolute
    top: 2.05rem
    left: -1.4rem

@media screen and (min-width: 1330px)
    .list-row .col.col--see-more
        width: 75%

    .list-row .notification-dot
        position: absolute
        top: 2.05rem
        left: -2.9rem

// -----------------------------------------------------------------------------
// Lists
// -----------------------------------------------------------------------------

.admin-section
    background-color: $color-yellow
    padding: 0.5rem 2rem

span.see-more-with-example__example-label
    display: inline-block
    background-color: #DDDDDD
    color: #333333
    border-radius: 2px
    font-size: 0.75rem
    line-height: 0.75rem
    padding: 0.5rem
    margin-right: 1rem

span.example-label__title
    font-size: 0.9em
    padding-bottom: 0.4rem
    display: inline-block

span.example-label
    display: inline-block
    width: 100%
    background-color: #DDDDDD
    color: #333333
    border-radius: 2px
    font-size: 1rem
    padding: 0.5rem
    word-break: break-all

div.signed
    color: #00A059

// -----------------------------------------------------------------------------
// Patient list
// -----------------------------------------------------------------------------
.filters-container
    display: flex
    gap: 2rem

    .filter_button
        display: flex
        align-items: center
        cursor: pointer

        a
            border-bottom: 1px solid transparent
            margin-right: 0.5rem
            transition: all 0.2s ease-in-out

        span
            display: block
            text-align: center
            vertical-align: center
            background-color: #cccccc
            border-radius: 2rem
            padding: 0 0.2rem
            min-width: 1.5rem
            min-height: 1.5rem
            line-height: 1.5rem
            font-size: 0.8rem
            transition: all 0.2s ease-in-out

        &:hover
            a
                border-bottom-color: $color-link-active

        &.filter_button--active
            a
                border-bottom-color: $color-dark-blue-0

            span
                background-color: $color-dark-blue-0
                color: white

// -----------------------------------------------------------------------------
// Night-Report component
// -----------------------------------------------------------------------------

.report-section .section-title
    display: inline-flex
    align-items: center
    justify-content: flex-start
    padding-top: 1rem
    padding-bottom: 1rem
    cursor: pointer
.report-section.report-section--minified .section-title
    padding-bottom: 4rem
.report-section .section-title::after
    content: url('assets/images/down-arrow.svg')
    padding-left: 1rem
.report-section.report-section--minified .section-title::after
    content: url('assets/images/arrowUp.svg')
    padding-right: 1rem


.report-section.report-section--minified .section-content
    display: none

.report-section .section-content .graph
    display: block
    width: 100%
    height: 25rem

.report-section .section-content .discreteGraph
    display: block
    width: 100%
    height: 15rem

.report-section .section-content .synchronizedGraph
    display: block
    width: 100%

// -----------------------------------------------------------------------------
// Home page
// -----------------------------------------------------------------------------
.home-wrapper
    display: flex
    padding-top: 2rem
    padding-bottom: 2rem
    margin-left: auto
    margin-right: auto
    width: 80%

    .home-wrapper__content-wrapper
        width: 50%
        height: calc(100vh - 8.6875rem)
        display: flex
        flex-direction: column
        gap: 4rem

        .content-wrapper__description-wrapper
            display: flex
            height: 50%
            flex-direction: column
            justify-content: flex-end
            gap: 2rem
            visibility: visible
            opacity: 100
            transition: all ease 0.5s
            margin-right: 4rem

            .title__text
                font-family: 'Plantin Infant', 'Times New Roman', serif
                line-height: normal
                font-size: 3rem
                width: 70%

            .title__number
                font-family: 'MaisonNeue Book', Arial, sans-serif
                font-size: 1.6rem
                margin-right: 0.5rem

            .description
                font-size: 1.6rem

        .content-wrapper__connection-wrapper
            display: flex
            gap: 1rem
            align-items: center

            .connection__login-button
                color: #00053c
                border-bottom: 1px solid #00053c

                &:hover
                    cursor: pointer

    .home-wrapper__image
        width: 50%

        .home_image
            display: block
            height: calc(100vh - 8.6875rem)
            object-fit: cover
            object-position: center

        .owl-dots
            position: absolute
            bottom: 1.875rem
            width: 100%

            .owl-dot
                width: 0.8rem
                height: 0.8rem
                margin-left: 0.375rem
                margin-right: 0.375rem
                background-color: $color-white
                border-radius: 1.1rem

                span
                    display: none

                &:not(.active)
                    opacity: .3

@media screen and (max-width: 1329px)
    .home-wrapper
        flex-direction: column-reverse
        gap: 2rem
        width: 100%
        padding-left: 2rem
        padding-right: 2rem

        .home-wrapper__content-wrapper
            justify-content: space-between
            width: 100%
            height: auto
            min-height: calc((100vh - 10.6875rem) - (100vw - 4rem))

            .content-wrapper__description-wrapper
                .title__text
                    width: auto
            .content-wrapper__connection-wrapper
                flex-direction: column

                .or-text
                    display: none

        .home-wrapper__image
            height: calc(100vw - 4rem)
            width: 100%

            .home_image
                height: calc(100vw - 4rem)

// -----------------------------------------------------------------------------
// Night-Report-Tab-Line component
// -----------------------------------------------------------------------------
.information-wrapper__popup p, .night-report-tab-line__value-wrapper p
    margin-bottom: 0

.description-wrapper__extended-name, .popup__description
    font-size: 1rem

.night-report-tab-line
    position: relative
    display: flex
    min-height: 6rem
    padding-top: 0.8rem
    padding-bottom: 0.8rem
    border-bottom: 1px solid $color-font

.content > *:last-child > .night-report-tab-line
    border-bottom-width: 0

.night-report-tab-line__description-wrapper, .night-report-tab-line__value-wrapper
    display: flex
    flex-direction: column
    justify-content: center
    width: 100%

.description-wrapper__name-wrapper
    display: flex
    align-items: center

.name-wrapper__information-wrapper
    margin-left: 0.625rem
    background: #FFF
    border-radius: 50%

    .information-wrapper__icon
        display: block
        width: 14px
        height: 14px
        border-radius: 50%
        border: 1px solid #04073F
        color: #04073F
        cursor: pointer
        transition: 0.5s

    .information-wrapper__popup
        position: absolute
        left: 0
        z-index: 1000
        display: block
        padding-left: 1.25rem
        padding-right: 1.25rem
        background: #FFF
        border-radius: 8px
        box-shadow: 0 8px 18px rgba(0, 0, 0, 0.40)
        transition: 0.5s
        overflow-x: hidden
        overflow-y: auto

        .popup__name
            margin-top: -0.75rem
            padding-bottom: 0.75rem

        .popup__description
            margin-bottom: -0.75rem
            line-height: 1.75

        &:before
            content: ''
            display: block
            position: sticky
            z-index: 2
            height: 2rem
            width: 100%
            top: 0
            left: 0
            background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))

        &:after
            content: ''
            display: block
            position: sticky
            z-index: 2
            height: 2rem
            width: 100%
            bottom: 0
            left: 0
            background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))

        &::-webkit-scrollbar
            width: 4px

        &::-webkit-scrollbar-track
            background-color: transparent

        &::-webkit-scrollbar-thumb
            background-color: darkgrey
            border-radius: 2px


    .information-wrapper__popup-arrow
        width: 0
        height: 0
        z-index: 1000
        border-top: 12px solid #FFF
        border-left: 12px solid transparent
        border-right: 12px solid transparent
        transition: 0.5s

@media screen and (min-width: 1330px)
    .name-wrapper__information-wrapper
        position: relative

        .information-wrapper__popup
            max-height: 22rem
            min-width: 55rem
            visibility: hidden
            opacity: 0
            transform: translateX(-4rem) translateY(calc(-100% - 5rem))
            transition-delay: 0.5s

        .information-wrapper__popup-arrow
            position: absolute
            left: -5px
            visibility: hidden
            opacity: 0
            transform: translateY(-5rem)
            transition-delay: 0.5s

        &:hover
            .information-wrapper__popup
                opacity: 1
                transform: translateX(-4rem) translateY(calc(-100% - 2.5rem))
                visibility: visible
                transition-delay: 0s
            .information-wrapper__popup-arrow
                opacity: 1
                visibility: visible
                transform: translateY(-2.5rem)
                transition-delay: 0s

    .night-report-tab-line__value-wrapper
        position: relative

        .value-wrapper__benchmark-wrapper
            position: absolute
            right: 0
            margin-top: 0

@media screen and (max-width: 1329px)
    .name-wrapper__information-wrapper
        .information-wrapper__popup
            right: 0
            max-height: 20rem
            max-width: 30rem
            transform: translateY(calc(-100% - 35px))

        .information-wrapper__popup--hidden
            visibility: hidden
            opacity: 0

        .information-wrapper__popup-arrow
            position: absolute
            left: var(--popup-arrow-left)
            transform: translateY(-36px)

        .information-wrapper__popup-arrow--hidden
            visibility: hidden
            opacity: 0

.value-wrapper__benchmark-wrapper
    display: flex

    &:first-child
        width: 100%

    &:not(:first-child)
        width: 50%

    &.benchmark-wrapper--reverse
        flex-direction: row-reverse

    .benchmark-wrapper__benchmark-range
        position: relative
        width: 33.333333%
        padding-top: 12px
        .benchmark-range__range-image
            position: absolute
            left: calc(50% - 12px)
            top: 0
            display: none
            width: 24px
            height: 24px
        .benchmark-range__range-line
            display: block
            width: 100%
            height: 4px
            background-color: #DDDDDD
        .benchmark-range__range-text
            display: block
            width: 100%
            padding-top: 5px
            text-align: center
            font-size: 0.75em
        &.benchmark-range--active
            .benchmark-range__range-image
                display: block
            &.benchmark-range--green
                .benchmark-range__range-line
                    background-color: #37CB88
            &.benchmark-range--yellow
                .benchmark-range__range-line
                    background-color: #FBD545
            &.benchmark-range--orange
                .benchmark-range__range-line
                    background-color: #FF854E
            &.benchmark-range--red
                .benchmark-range__range-line
                    background-color: #E8414B

// -----------------------------------------------------------------------------
// Date picker input
// -----------------------------------------------------------------------------

.mat-datepicker-input
    display: block
    width: 100%
    font-family: inherit
    font-size: 0.9em
    border: 0

    &:focus
        outline: none

.mat-datepicker-toggle
    position: absolute
    right: 1rem

.date-picker__input
    display: flex
    position: relative
    width: 100%
    padding: 1.5rem
    border: 1px solid $color-font
    align-items: center
    margin-bottom: 1rem

// Need to update material style to prevent a display bug
// where days go out of date picker container
.mat-datepicker-content-container
    padding-bottom: 1rem
    padding-bottom: 1rem

.mat-calendar-content
    .mat-calendar-body-label.ng-star-inserted
        padding-top: 0 !important
        padding-bottom: 0 !important

// -----------------------------------------------------------------------------
// Phone number input
// -----------------------------------------------------------------------------

.iti
    display: block
    margin-bottom: 1rem

.iti input
    display: block
    width: 100%
    font-family: inherit
    font-size: 0.9em
    border: 0
    padding-left: 0.5rem !important

    &:focus
        outline: none

.separate-dial-code
    display: flex
    width: 100%
    padding: 1.5rem
    border: 1px solid $color-font

.iti__flag-container
    position: relative

.iti__selected-flag
    width: auto !important

.selected-dial-code
    font-size: 0.9em
    line-height: 1rem

// -----------------------------------------------------------------------------
// Modal
// -----------------------------------------------------------------------------
.modal
    background-color: rgba(0,0,0, 0.38)
    bottom: 0
    height: 100%
    left: 0
    overflow: hidden
    padding: 0 16px
    position: fixed
    right: 0
    top: 0
    text-align: center
    z-index: 100
    opacity: 1
.modal-open
    overflow: hidden
.modal-content
    background-color: white
    border-radius: 4px
    margin: 16px auto
    margin-top: 20rem
    max-width: 580px
    position: relative
    transition: opacity 400ms ease-in-out
    width: 100%
    will-change: opacity
    text-align: left
.modal-content-size-m
    max-width: 992px
.modal-content-size-l
    max-width: 1200px
.modal-footer
    -webkit-box-align: center
    -ms-flex-align: center
    align-items: center
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    justify-content: space-evenly
    padding: 1rem
    border-top: 1px solid #cecece
.modal-header
    -webkit-box-align: center
    -ms-flex-align: center
    align-items: center
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    padding: 1rem
    border-bottom: 1px solid #cecece
.modal-body
    padding: 16px
.modal-entry
    height: 20rem
    width: 100%
    font: inherit
    font-size: 14px
    background-color: rgba(0,0,0,0.12)

// -----------------------------------------------------------------------------
// Others
// -----------------------------------------------------------------------------

.products-notifications__switch .switch-button-container
    margin-bottom: 2rem

.save-password-button
    margin-left: 0

.children-tab-lines-other-than-first-faded > :not(:first-child) > .night-report-tab-line
    border-bottom: 1.5px dashed #444444
    color: #444444

.children-tab-lines-other-than-first-faded > *:last-child > .night-report-tab-line
    border-bottom: 0px

// -----------------------------------------------------------------------------
// Onboarding
// -----------------------------------------------------------------------------

.introjs-tooltip.customTooltip
    min-width: 40rem
    max-width: 40rem
    border-radius: 0px

.customTooltip .introjs-tooltip-title
    color: $color-dark-blue-0
    font-family: 'MaisonNeue Book', Arial, sans-serif
    font-size: 1.2em

.customTooltip .introjs-tooltiptext
    font-family: 'MaisonNeue Book', Arial, sans-serif

.customTooltip .introjs-button:focus,
.customTooltip .introjs-button
    font-family: 'MaisonNeue Book', Arial, sans-serif
    background-color: $color-dark-blue-0
    border: 1px solid $color-dark-blue-0
    color: $color-white
    font-size: 0.8em
    text-align: center
    transition: background-color .2s ease-out
    text-shadow: none
    box-shadow: none
    border-radius: 0px

.customTooltip .introjs-button:active,
.customTooltip .introjs-button:hover
    background-color: $color-dark-blue-2
    color: $color-white
    cursor: pointer
    border: 1px solid $color-dark-blue-0

.customTooltip .introjs-tooltiptext b
    font-weight: bold

.customTooltip .introjs-tooltiptext div.space
    display: block
    width: 100%
    height: 0.5em

.customTooltip .introjs-skipbutton
    border: none

.customTooltip .introjs-dontShowAgain label
    font-family: 'MaisonNeue Book', Arial, sans-serif
    font-size: 0.7em
    color: black
    vertical-align: middle
    display: none

.customTooltip .introjs-dontShowAgain
    padding-bottom: 20px

.customTooltip .introjs-dontShowAgain input
    vertical-align: middle
    width: 1em
    height: 1em
    border-color: black
    display: none

.customTooltip .introjs-tooltipbuttons
    border: none
    padding-left: 20px
    padding-right: 20px

.highlighted
    border: 3px solid $color-red
    border-radius: 0.5rem
    padding: 2px
    transition: border-color 0.8s ease-out

.not-highlighted
    border: 3px solid rgba(0,0,0,0)
    border-radius: 0.5rem
    padding: 2px
    transition: border-color 0.8s ease-out

.sorting-arrow
    margin-left: 8px
    height: 0.6rem

    &-up
        transform: rotate(180deg)
// -----------------------------------------------------------------------------
// Image viewer
// -----------------------------------------------------------------------------
.image_viewer-container
    display: flex
    position: fixed
    z-index: 999
    left: 0
    top: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.90)

    .image_viewer_image
        margin: auto
        display: block
        padding: 1rem
        max-width: 75%
        max-height: 75%
        background-color: $color-white
        -webkit-animation-name: zoom
        -webkit-animation-duration: 0.6s
        animation-name: zoom
        animation-duration: 0.6s

    .image_viewer-close_button
        position: absolute
        top: 4rem
        right: 4rem

// -----------------------------------------------------------------------------
// Signatures
// -----------------------------------------------------------------------------

.signature
    height: 4rem
    object-fit: contain

.signature--small
    height: 2.5rem
    transition: all 0.2s ease-in-out

.dropper-container
    width: 100%
    border: 1px dashed $color-dark-blue-0
    border-radius: 8px
    display: flex
    flex-direction: column
    align-items: center
    padding: 2.5rem
    gap: 0.5rem

    .dropper-container__upload-img
        width: 3rem
        margin-bottom: 0.5rem

    .dropper-container__browse-button
        min-width: 0

.new_signature-container
    display: flex
    align-items: center
    gap: 2rem

// -----------------------------------------------------------------------------
// Night list
// -----------------------------------------------------------------------------

.img_check
    height: 32px
    vertical-align: middle
    filter: grayscale(1)
