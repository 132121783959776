html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "Plantin Infant";
  src: url("/assets/fonts/Plantin-Infant.otf") format("opentype");
}
@font-face {
  font-family: "MaisonNeue Book";
  src: url("/assets/fonts/MaisonNeue-Book.otf") format("opentype");
}
@font-face {
  font-family: "Sunrise Regular";
  src: url("/assets/fonts/Sunrise-Regular.otf") format("opentype");
}
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden; /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item.center {
  z-index: 5;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}
.owl-carousel .owl-item .owl-lazy {
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*=owl-] {
  color: #FFFFFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFFFFF;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  line-height: 10px;
}

body {
  font-family: "MaisonNeue Book", Arial, sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 2rem;
}

a {
  border-bottom: 1px solid #04073F;
  color: #04073F;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}

a.no-border {
  border-bottom-width: 0;
}

a:active, a:hover {
  color: #323296;
}

a.internal {
  border-bottom-width: 0;
  display: flex;
  align-items: center;
}
a.internal-arrow {
  padding-left: 0.5rem;
  background-image: url("assets/images/right-arrow.svg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 1.3rem;
}
a.internal span {
  margin-right: 2rem;
}

p {
  margin-bottom: 1.25rem;
}

input::placeholder {
  color: #9c9c9c;
}

textarea {
  resize: none;
  font: inherit;
  font-size: inherit;
  width: 100%;
  padding: 1.5rem;
  border-radius: 0;
  border-color: black;
  height: 20rem;
}

textarea::placeholder {
  color: #9c9c9c;
}

.pointer-hover:hover {
  cursor: pointer;
}

.small {
  font-size: 1.2rem;
}

.bold {
  font-weight: bold;
}

.small-note {
  font-size: 0.8rem;
  color: #323296;
  font-weight: bold;
}
.small-note.small-note--errored {
  color: #FF3C46;
}

.guttered,
.guttered-h {
  padding-left: 2.2rem;
  padding-right: 2.2rem;
}

.guttered,
.guttered-v {
  padding-top: 2.2rem;
  padding-bottom: 2.2rem;
}

.title-h1,
.title-h2 {
  font-family: "Plantin Infant", "Times New Roman", serif;
  font-weight: 400;
  line-height: 1em;
}

.title-h1 {
  font-size: 2.9rem;
}

.title-h2 {
  font-size: 2.2rem;
}

.title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.title-row .title-row__left-content {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.title-row .title-row__right-content {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.hide-on-desktop {
  display: block;
}

.hide-on-mobile {
  display: none;
}

.separator {
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #000000;
}

.separator--subsection {
  margin-bottom: 3rem;
}

.display-none {
  display: none !important;
}

.hidden {
  visibility: hidden !important;
  opacity: 0 !important;
}

.delete-button {
  color: #000000;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.delete-button:hover {
  color: #404040;
  text-decoration: none;
  cursor: pointer;
}
.delete-button.delete-button__light {
  color: #FFFFFF;
}
.delete-button.delete-button__light:hover {
  color: #bbb;
}

@media screen and (min-width: 640px) and (max-width: 1329px) {
  html {
    font-size: 16px;
  }
}
@media screen and (min-width: 1330px) {
  html {
    font-size: calc(10px + 0.4vw - 4px);
  }
  .hide-on-desktop {
    display: none;
  }
  .hide-on-mobile {
    display: block;
  }
}
.navbar-wrapper {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 99;
}

.navbar {
  position: relative;
  display: flex;
  align-items: center;
  height: 4.6875rem;
  margin-left: 2rem;
  margin-right: 2rem;
  border-bottom: 1px solid black;
}
.navbar .navbar__logo-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.navbar .navbar__logo-wrapper .navbar__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
.navbar .navbar__logo-wrapper .navbar__logo:hover {
  cursor: pointer;
}
.navbar .navbar__logo-wrapper .navbar__logo .logo__sun, .navbar .navbar__logo-wrapper .navbar__logo .logo__icon, .navbar .navbar__logo-wrapper .navbar__logo .logo__rise {
  height: 2.1875rem;
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
}
.navbar .navbar__logo-wrapper .navbar__logo .logo__sun {
  width: 4.125rem;
  background-image: url("assets/images/navbar-logo/img_logo_sunrise_black_part_1.svg");
}
.navbar .navbar__logo-wrapper .navbar__logo .logo__icon {
  width: 1.9375rem;
  transform-origin: 50% 57.14% 0;
  background-image: url("assets/images/navbar-logo/img_logo_sunrise_black_part_2.svg");
}
.navbar .navbar__logo-wrapper .navbar__logo .logo__rise {
  width: 4.0625rem;
  background-image: url("assets/images/navbar-logo/img_logo_sunrise_black_part_3.svg");
}
.navbar .navbar__buttons-wrapper {
  position: absolute;
}
.navbar .navbar__buttons-wrapper .link:hover {
  color: #33378D;
  cursor: pointer;
}
.navbar .account-link-wrapper {
  display: flex;
  align-items: center;
}
.navbar .account-link-wrapper .warning-wrapper__icon {
  width: 1.3rem;
  height: 1.3rem;
  vertical-align: middle;
}
.navbar .account-link-wrapper .warning-wrapper__icon + .link {
  margin-left: 0.625rem;
}
.navbar .lang {
  text-transform: uppercase;
}

@media screen and (min-width: 1330px) {
  .navbar__buttons-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
    height: 100%;
  }
  .navbar__buttons-wrapper .navbar__links {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40%;
  }
  .navbar__buttons-wrapper .navbar__links:last-child {
    margin-left: auto;
  }
  .navbar__buttons-wrapper .navbar__links .link {
    margin-left: 1.5rem;
    color: #00053c;
    border-bottom: 1px solid transparent;
    transition: all ease 250ms;
  }
  .navbar__buttons-wrapper .navbar__links .link:hover {
    border-bottom-color: #00053c;
  }
  .navbar__buttons-wrapper .navbar__links .link:hover .link__sub-links {
    opacity: 100;
    visibility: visible;
    transform: translateY(0);
    transition-delay: 0s;
  }
  .navbar__buttons-wrapper .navbar__links .link:first-child {
    margin-left: 0;
  }
  .navbar__buttons-wrapper .navbar__links .link.link--active {
    border-bottom-color: #00053c;
  }
  .navbar__buttons-wrapper .navbar__links .link__sub-links {
    position: absolute;
    top: 4.5rem;
    left: -1rem;
    padding: 0.5rem 1rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0.2rem 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    transform: translateY(1rem);
    opacity: 0;
    visibility: hidden;
    transition: all 500ms ease;
    transition-delay: 0.5s;
  }
  .navbar__buttons-wrapper .navbar__links .link__sub-links:hover {
    opacity: 100;
    visibility: visible;
    transform: translateY(0);
    transition-delay: 0s;
  }
  .navbar__buttons-wrapper .navbar__links .link__sub-links .sub-link {
    color: #00053c;
    border-bottom: 1px solid transparent;
    transition: all ease 250ms;
    text-transform: uppercase;
  }
  .navbar__buttons-wrapper .navbar__links .link__sub-links .sub-link:hover {
    border-bottom-color: #00053c;
    cursor: pointer;
  }
  .navbar__buttons-wrapper .lang-link::after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #00053c;
    margin-left: 1.5rem;
  }
}
@media screen and (max-width: 1329px) {
  .navbar {
    justify-content: center;
  }
  .navbar.connected-navbar .navbar__links:nth-child(1) {
    order: 3;
  }
  .navbar.connected-navbar .navbar__links:nth-child(2) {
    order: 1;
  }
  .navbar.connected-navbar .navbar__links:nth-child(3) {
    order: 2;
  }
  .navbar__burger {
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 1.75rem;
    height: 1.5rem;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    border-radius: 0;
    outline: none;
    z-index: 9;
  }
  .navbar__burger:hover {
    cursor: pointer;
  }
  .navbar__burger.navbar__burger--active .burger__line:nth-child(1) {
    width: 110%;
    transform: rotate(45deg);
  }
  .navbar__burger.navbar__burger--active .burger__line:nth-child(2) {
    opacity: 0;
  }
  .navbar__burger.navbar__burger--active .burger__line:nth-child(3) {
    width: 110%;
    transform: rotate(-45deg);
  }
  .navbar__burger .burger__line {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #00053c;
    transition: all ease 0.5s;
    transform-origin: 0 0;
  }
  .navbar__buttons-wrapper {
    display: flex;
    flex-direction: column;
    top: 4.6875rem;
    left: -1.5rem;
    width: calc(100% + 3rem);
    height: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: white;
    overflow-y: auto;
    transition: all ease 0.5s;
  }
  .navbar__buttons-wrapper.navbar__buttons-wrapper--active {
    height: calc(100vh - 4.6875rem);
  }
  .navbar__buttons-wrapper .link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-bottom: 1px solid #00053c;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    color: #00053c;
  }
  .navbar__buttons-wrapper .link .link__sub-links {
    display: none;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar__buttons-wrapper .link .link__sub-links.link__sub-links--active {
    display: block;
  }
  .account-link-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #00053c;
  }
  .account-link-wrapper .link {
    border-bottom: none;
  }
  .account-link-wrapper .warning-wrapper__icon {
    width: 1.3rem;
    height: 1.3rem;
    vertical-align: middle;
  }
  .account-link-wrapper .warning-wrapper__icon + .link {
    margin-left: 0.625rem;
  }
}
.router {
  min-height: calc(100vh - 22rem - 4.6875rem);
}

@media screen and (min-width: 1330px) {
  .router {
    min-height: calc(100vh - 6rem - 4.6875rem - 2px);
  }
}
.footer {
  display: block;
  height: 22rem;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
}

.footer .footer-items {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.footer .footer-item {
  display: block;
  width: 100%;
  height: 3rem;
  text-align: center;
  border-bottom-width: 0;
  color: #000000;
}

.footer a.footer-item:active,
.footer a.footer-item:hover {
  color: #323296;
}

@media screen and (min-width: 1330px) {
  .footer {
    height: 6rem;
  }
  .footer .footer-items {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    padding-top: 0;
    padding-bottom: 0;
    align-items: center;
  }
  .footer .footer-items .footer-item {
    width: auto;
    height: auto;
    margin-left: 2rem;
  }
}
.row {
  width: 100%;
}

.row.row--guttered,
.row.row--guttered-h {
  padding-left: 2rem;
  padding-right: 2rem;
}

.row.row--guttered,
.row.row--guttered-v {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.row.row--guttered-v2 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.row.row--flex {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 60rem) {
  .row.row--tiny {
    width: 60rem;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1330px) {
  .row.row--smaller {
    width: 80rem;
    margin: 0 auto;
  }
  .row.row--guttered.content,
  .row.row--guttered.row--guttered-v.content {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
.title-h1,
.title-h2,
.title-h3 {
  font-family: "Plantin Infant";
  font-weight: 400;
  line-height: 1em;
}

.title-h1 {
  font-size: 2.9rem;
}

.title-h2 {
  font-size: 2.2rem;
}

.title-h3 {
  font-size: 1.8rem;
}

.button-like {
  display: inline-block;
  margin: 1.5rem auto;
  font-size: 0.9em;
  text-align: center;
}
.button-like:hover + .button-like--tooltip {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.button-like.button-like--no-margin {
  margin: 0;
}

.button-like.button-like--is-loading,
.button-like.button-like--is-loading:active,
.button-like.button-like--is-loading:hover {
  color: #323296;
  opacity: 0.9;
  cursor: wait;
}

.button-like.button-like--disabled,
.button-like.button-like--disabled:active,
.button-like.button-like--disabled:hover {
  color: #323296;
  opacity: 0.9;
  cursor: default;
  padding-bottom: 0;
  border-bottom: none;
}

.button-like--tooltip {
  position: absolute;
  top: -50%;
  left: 60%;
  right: 10%;
  transform: translateY(1.5rem);
  opacity: 0;
  visibility: hidden;
  padding: 0.5rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  color: #04073F;
  font-size: 0.9em;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  transition-delay: 0.5s;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.logo_loading {
  -webkit-animation: rotating 1.5s linear infinite;
  -moz-animation: rotating 1.5s linear infinite;
  -ms-animation: rotating 1.5s linear infinite;
  -o-animation: rotating 1.5s linear infinite;
  animation: rotating 1.5s linear infinite;
  height: 70%;
  width: auto;
  margin-top: 2px;
}

.field-readonly {
  font-size: 0.9em;
  color: #404040;
}

.field-readonly,
.field-container,
.validatable-container,
.label,
.field,
.error-text,
.hint-text {
  width: 100%;
  display: block;
}

.field-container {
  margin-bottom: 2rem;
}

.field-container .label,
.field-container .field,
.field-container .error-text,
.field-container .hint-text {
  margin-bottom: 1rem;
}

.field-container .field {
  padding: 1.5rem;
  font-family: inherit;
  font-size: 0.9em;
  border: 1px solid #000000;
}

@media screen and (min-width: 60rem) {
  .two-field-container-per-row {
    display: flex;
  }
  .two-field-container-per-row > .field-container,
  .two-field-container-per-row > .validatable-container {
    width: 50%;
  }
  .two-field-container-per-row > .field-container:nth-child(1),
  .two-field-container-per-row > .validatable-container:nth-child(1) {
    padding-right: 1rem;
  }
  .two-field-container-per-row > .field-container:nth-child(2),
  .two-field-container-per-row > .validatable-container:nth-child(2) {
    padding-left: 1rem;
  }
}
.three-field-container-per-row {
  display: flex;
  flex-direction: column;
}

.three-field-container-per-row .field-container {
  width: 100%;
  margin-bottom: 1rem;
}

@media screen and (min-width: 60rem) {
  .three-field-container-per-row {
    flex-direction: row;
  }
  .three-field-container-per-row .field-container {
    width: 33.333333%;
  }
  .three-field-container-per-row .field-container:first-child {
    padding-right: 1rem;
  }
  .three-field-container-per-row .field-container:last-child {
    padding-left: 1rem;
  }
}
.field-container.required .label::after {
  content: "*";
  margin-left: 0.5rem;
  color: #FF3C46;
}

.field-container--checkbox.disabled {
  opacity: 0.25;
  cursor: not-allowed;
}
.field-container--checkbox.disabled .label, .field-container--checkbox.disabled input, .field-container--checkbox.disabled .checkmark {
  cursor: not-allowed !important;
}
.field-container--checkbox.disabled .checkmark {
  background-color: #9c9c9c;
}

.field-container .error-text {
  display: none;
  color: #FF3C46;
  font-size: 1rem;
  line-height: 1.5rem;
}

.field-container.field-container--errored .label {
  color: #FF3C46;
}

.field-container.field-container--errored .field,
.field-container.field-container--errored .separate-dial-code,
.field-container.field-container--errored .date-picker__input {
  border-color: #FF3C46;
}

.field-container.field-container--errored .error-text {
  display: block;
}

.field-container .hint-text {
  font-size: 1rem;
  line-height: 1.5rem;
}

.field-container.field-container--checkbox {
  position: relative;
  padding-left: 2.5rem;
}

.field-container.field-container--checkbox .error-text {
  margin-left: -2.5rem;
}

.field-container.field-container--checkbox .hint-text {
  margin-left: -2.5rem;
}

.field-container.field-container--checkbox input {
  position: absolute;
  top: 0.05rem;
  left: 0;
  width: 1.75rem;
  height: 1.75rem;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.field-container.field-container--checkbox .checkmark {
  position: absolute;
  top: 0.05rem;
  left: 0;
  width: 1.75rem;
  height: 1.75rem;
  border: 1px solid #000000;
}

.field-container.field-container--checkbox:hover:not(.disabled) input ~ .checkmark {
  background-color: rgba(50, 50, 150, 0.125);
}

.field-container.field-container--checkbox input:checked ~ .checkmark {
  background-color: #04073F !important;
  border-color: #04073F !important;
}

.field-container.field-container--checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.field-container.field-container--checkbox input:checked ~ .checkmark:after {
  display: block;
}

.field-container.field-container--checkbox .checkmark:after {
  top: 0.1rem;
  left: 0.5rem;
  width: 0.5rem;
  height: 1rem;
  border: solid #FFFFFF;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.field-password-container {
  position: relative;
  width: 100% !important;
}
.field-password-container > .password-input {
  padding-right: 7rem !important;
}

.password-visibility-button {
  position: absolute;
  padding: 1.4rem;
  top: 0;
  right: 0;
  cursor: pointer;
}

.password-visibility-text {
  font-size: 0.9em;
}
.password-visibility-text::selection {
  background: none;
}

.two-buttons-container {
  display: flex;
}
.two-buttons-container > *:not(:last-child) {
  margin-right: 2rem;
}

.list-row {
  position: relative;
  display: flex;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #000000;
}

.list-row.list-row--no-border {
  border-bottom-width: 0;
}

.list-row.list-row--no-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.list-row.list-row--no-top-padding {
  padding-top: 0;
}

.list-row.list-row--title {
  font-size: 1.6rem;
  font-weight: bold;
}

.list-row.list-row--header {
  font-weight: bold;
  align-items: flex-end;
}

.list-row.list-row--clickable:hover {
  background-color: #f4f4f4;
  cursor: pointer;
}

.list-row.list-row--warning {
  border-bottom: 0px;
  cursor: pointer;
  background-color: #FF854E;
  color: white;
  margin-bottom: 3rem;
  border-radius: 0.8rem;
}
.list-row.list-row--warning:first-child {
  padding-left: 1rem;
}

.list-row.list-row--disabled,
.list-row.list-row--clickable.list-row--disabled:hover {
  background-color: transparent;
  color: #aaa;
  cursor: default;
}

.list-row.list-row--not-clickable,
.list-row.list-row--not-clickable.list-row--not-clickable:hover {
  background-color: #dedede;
  cursor: default;
}

.list-row .col {
  width: 100%;
  display: flex;
  align-items: center;
}

.list-row .col--smaller {
  width: 50%;
}

.list-row .col--longer {
  width: 140%;
}

.list-row .col--tiny {
  width: 2rem;
}

.list-row .col.col--see-more {
  justify-content: flex-end;
}

.list-row .col.col--clickable {
  cursor: pointer;
}

.list-row .col.col--subtitle div:nth-child(1) {
  display: block;
}
.list-row .col.col--subtitle div:nth-child(1) div:nth-child(2) {
  font-weight: lighter;
  font-size: 0.7em;
}

.notification-dot {
  display: inline-block;
  width: 0.9rem;
  height: 0.9rem;
  background-color: #FF3C46;
  border-radius: 1rem;
}

.list-row .notification-dot {
  position: absolute;
  top: 2.05rem;
  left: -1.4rem;
}

@media screen and (min-width: 1330px) {
  .list-row .col.col--see-more {
    width: 75%;
  }
  .list-row .notification-dot {
    position: absolute;
    top: 2.05rem;
    left: -2.9rem;
  }
}
.admin-section {
  background-color: #FBD545;
  padding: 0.5rem 2rem;
}

span.see-more-with-example__example-label {
  display: inline-block;
  background-color: #DDDDDD;
  color: #333333;
  border-radius: 2px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  padding: 0.5rem;
  margin-right: 1rem;
}

span.example-label__title {
  font-size: 0.9em;
  padding-bottom: 0.4rem;
  display: inline-block;
}

span.example-label {
  display: inline-block;
  width: 100%;
  background-color: #DDDDDD;
  color: #333333;
  border-radius: 2px;
  font-size: 1rem;
  padding: 0.5rem;
  word-break: break-all;
}

div.signed {
  color: #00A059;
}

.filters-container {
  display: flex;
  gap: 2rem;
}
.filters-container .filter_button {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.filters-container .filter_button a {
  border-bottom: 1px solid transparent;
  margin-right: 0.5rem;
  transition: all 0.2s ease-in-out;
}
.filters-container .filter_button span {
  display: block;
  text-align: center;
  vertical-align: center;
  background-color: #cccccc;
  border-radius: 2rem;
  padding: 0 0.2rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.8rem;
  transition: all 0.2s ease-in-out;
}
.filters-container .filter_button:hover a {
  border-bottom-color: #323296;
}
.filters-container .filter_button.filter_button--active a {
  border-bottom-color: #04073F;
}
.filters-container .filter_button.filter_button--active span {
  background-color: #04073F;
  color: white;
}

.report-section .section-title {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
}

.report-section.report-section--minified .section-title {
  padding-bottom: 4rem;
}

.report-section .section-title::after {
  content: url("assets/images/down-arrow.svg");
  padding-left: 1rem;
}

.report-section.report-section--minified .section-title::after {
  content: url("assets/images/arrowUp.svg");
  padding-right: 1rem;
}

.report-section.report-section--minified .section-content {
  display: none;
}

.report-section .section-content .graph {
  display: block;
  width: 100%;
  height: 25rem;
}

.report-section .section-content .discreteGraph {
  display: block;
  width: 100%;
  height: 15rem;
}

.report-section .section-content .synchronizedGraph {
  display: block;
  width: 100%;
}

.home-wrapper {
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
.home-wrapper .home-wrapper__content-wrapper {
  width: 50%;
  height: calc(100vh - 8.6875rem);
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.home-wrapper .home-wrapper__content-wrapper .content-wrapper__description-wrapper {
  display: flex;
  height: 50%;
  flex-direction: column;
  justify-content: flex-end;
  gap: 2rem;
  visibility: visible;
  opacity: 100;
  transition: all ease 0.5s;
  margin-right: 4rem;
}
.home-wrapper .home-wrapper__content-wrapper .content-wrapper__description-wrapper .title__text {
  font-family: "Plantin Infant", "Times New Roman", serif;
  line-height: normal;
  font-size: 3rem;
  width: 70%;
}
.home-wrapper .home-wrapper__content-wrapper .content-wrapper__description-wrapper .title__number {
  font-family: "MaisonNeue Book", Arial, sans-serif;
  font-size: 1.6rem;
  margin-right: 0.5rem;
}
.home-wrapper .home-wrapper__content-wrapper .content-wrapper__description-wrapper .description {
  font-size: 1.6rem;
}
.home-wrapper .home-wrapper__content-wrapper .content-wrapper__connection-wrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.home-wrapper .home-wrapper__content-wrapper .content-wrapper__connection-wrapper .connection__login-button {
  color: #00053c;
  border-bottom: 1px solid #00053c;
}
.home-wrapper .home-wrapper__content-wrapper .content-wrapper__connection-wrapper .connection__login-button:hover {
  cursor: pointer;
}
.home-wrapper .home-wrapper__image {
  width: 50%;
}
.home-wrapper .home-wrapper__image .home_image {
  display: block;
  height: calc(100vh - 8.6875rem);
  object-fit: cover;
  object-position: center;
}
.home-wrapper .home-wrapper__image .owl-dots {
  position: absolute;
  bottom: 1.875rem;
  width: 100%;
}
.home-wrapper .home-wrapper__image .owl-dots .owl-dot {
  width: 0.8rem;
  height: 0.8rem;
  margin-left: 0.375rem;
  margin-right: 0.375rem;
  background-color: #FFFFFF;
  border-radius: 1.1rem;
}
.home-wrapper .home-wrapper__image .owl-dots .owl-dot span {
  display: none;
}
.home-wrapper .home-wrapper__image .owl-dots .owl-dot:not(.active) {
  opacity: 0.3;
}

@media screen and (max-width: 1329px) {
  .home-wrapper {
    flex-direction: column-reverse;
    gap: 2rem;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .home-wrapper .home-wrapper__content-wrapper {
    justify-content: space-between;
    width: 100%;
    height: auto;
    min-height: calc(100vh - 10.6875rem - (100vw - 4rem));
  }
  .home-wrapper .home-wrapper__content-wrapper .content-wrapper__description-wrapper .title__text {
    width: auto;
  }
  .home-wrapper .home-wrapper__content-wrapper .content-wrapper__connection-wrapper {
    flex-direction: column;
  }
  .home-wrapper .home-wrapper__content-wrapper .content-wrapper__connection-wrapper .or-text {
    display: none;
  }
  .home-wrapper .home-wrapper__image {
    height: calc(100vw - 4rem);
    width: 100%;
  }
  .home-wrapper .home-wrapper__image .home_image {
    height: calc(100vw - 4rem);
  }
}
.information-wrapper__popup p, .night-report-tab-line__value-wrapper p {
  margin-bottom: 0;
}

.description-wrapper__extended-name, .popup__description {
  font-size: 1rem;
}

.night-report-tab-line {
  position: relative;
  display: flex;
  min-height: 6rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #000000;
}

.content > *:last-child > .night-report-tab-line {
  border-bottom-width: 0;
}

.night-report-tab-line__description-wrapper, .night-report-tab-line__value-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.description-wrapper__name-wrapper {
  display: flex;
  align-items: center;
}

.name-wrapper__information-wrapper {
  margin-left: 0.625rem;
  background: #FFF;
  border-radius: 50%;
}
.name-wrapper__information-wrapper .information-wrapper__icon {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid #04073F;
  color: #04073F;
  cursor: pointer;
  transition: 0.5s;
}
.name-wrapper__information-wrapper .information-wrapper__popup {
  position: absolute;
  left: 0;
  z-index: 1000;
  display: block;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  overflow-x: hidden;
  overflow-y: auto;
}
.name-wrapper__information-wrapper .information-wrapper__popup .popup__name {
  margin-top: -0.75rem;
  padding-bottom: 0.75rem;
}
.name-wrapper__information-wrapper .information-wrapper__popup .popup__description {
  margin-bottom: -0.75rem;
  line-height: 1.75;
}
.name-wrapper__information-wrapper .information-wrapper__popup:before {
  content: "";
  display: block;
  position: sticky;
  z-index: 2;
  height: 2rem;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0));
}
.name-wrapper__information-wrapper .information-wrapper__popup:after {
  content: "";
  display: block;
  position: sticky;
  z-index: 2;
  height: 2rem;
  width: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0));
}
.name-wrapper__information-wrapper .information-wrapper__popup::-webkit-scrollbar {
  width: 4px;
}
.name-wrapper__information-wrapper .information-wrapper__popup::-webkit-scrollbar-track {
  background-color: transparent;
}
.name-wrapper__information-wrapper .information-wrapper__popup::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 2px;
}
.name-wrapper__information-wrapper .information-wrapper__popup-arrow {
  width: 0;
  height: 0;
  z-index: 1000;
  border-top: 12px solid #FFF;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  transition: 0.5s;
}

@media screen and (min-width: 1330px) {
  .name-wrapper__information-wrapper {
    position: relative;
  }
  .name-wrapper__information-wrapper .information-wrapper__popup {
    max-height: 22rem;
    min-width: 55rem;
    visibility: hidden;
    opacity: 0;
    transform: translateX(-4rem) translateY(calc(-100% - 5rem));
    transition-delay: 0.5s;
  }
  .name-wrapper__information-wrapper .information-wrapper__popup-arrow {
    position: absolute;
    left: -5px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-5rem);
    transition-delay: 0.5s;
  }
  .name-wrapper__information-wrapper:hover .information-wrapper__popup {
    opacity: 1;
    transform: translateX(-4rem) translateY(calc(-100% - 2.5rem));
    visibility: visible;
    transition-delay: 0s;
  }
  .name-wrapper__information-wrapper:hover .information-wrapper__popup-arrow {
    opacity: 1;
    visibility: visible;
    transform: translateY(-2.5rem);
    transition-delay: 0s;
  }
  .night-report-tab-line__value-wrapper {
    position: relative;
  }
  .night-report-tab-line__value-wrapper .value-wrapper__benchmark-wrapper {
    position: absolute;
    right: 0;
    margin-top: 0;
  }
}
@media screen and (max-width: 1329px) {
  .name-wrapper__information-wrapper .information-wrapper__popup {
    right: 0;
    max-height: 20rem;
    max-width: 30rem;
    transform: translateY(calc(-100% - 35px));
  }
  .name-wrapper__information-wrapper .information-wrapper__popup--hidden {
    visibility: hidden;
    opacity: 0;
  }
  .name-wrapper__information-wrapper .information-wrapper__popup-arrow {
    position: absolute;
    left: var(--popup-arrow-left);
    transform: translateY(-36px);
  }
  .name-wrapper__information-wrapper .information-wrapper__popup-arrow--hidden {
    visibility: hidden;
    opacity: 0;
  }
}
.value-wrapper__benchmark-wrapper {
  display: flex;
}
.value-wrapper__benchmark-wrapper:first-child {
  width: 100%;
}
.value-wrapper__benchmark-wrapper:not(:first-child) {
  width: 50%;
}
.value-wrapper__benchmark-wrapper.benchmark-wrapper--reverse {
  flex-direction: row-reverse;
}
.value-wrapper__benchmark-wrapper .benchmark-wrapper__benchmark-range {
  position: relative;
  width: 33.333333%;
  padding-top: 12px;
}
.value-wrapper__benchmark-wrapper .benchmark-wrapper__benchmark-range .benchmark-range__range-image {
  position: absolute;
  left: calc(50% - 12px);
  top: 0;
  display: none;
  width: 24px;
  height: 24px;
}
.value-wrapper__benchmark-wrapper .benchmark-wrapper__benchmark-range .benchmark-range__range-line {
  display: block;
  width: 100%;
  height: 4px;
  background-color: #DDDDDD;
}
.value-wrapper__benchmark-wrapper .benchmark-wrapper__benchmark-range .benchmark-range__range-text {
  display: block;
  width: 100%;
  padding-top: 5px;
  text-align: center;
  font-size: 0.75em;
}
.value-wrapper__benchmark-wrapper .benchmark-wrapper__benchmark-range.benchmark-range--active .benchmark-range__range-image {
  display: block;
}
.value-wrapper__benchmark-wrapper .benchmark-wrapper__benchmark-range.benchmark-range--active.benchmark-range--green .benchmark-range__range-line {
  background-color: #37CB88;
}
.value-wrapper__benchmark-wrapper .benchmark-wrapper__benchmark-range.benchmark-range--active.benchmark-range--yellow .benchmark-range__range-line {
  background-color: #FBD545;
}
.value-wrapper__benchmark-wrapper .benchmark-wrapper__benchmark-range.benchmark-range--active.benchmark-range--orange .benchmark-range__range-line {
  background-color: #FF854E;
}
.value-wrapper__benchmark-wrapper .benchmark-wrapper__benchmark-range.benchmark-range--active.benchmark-range--red .benchmark-range__range-line {
  background-color: #E8414B;
}

.mat-datepicker-input {
  display: block;
  width: 100%;
  font-family: inherit;
  font-size: 0.9em;
  border: 0;
}
.mat-datepicker-input:focus {
  outline: none;
}

.mat-datepicker-toggle {
  position: absolute;
  right: 1rem;
}

.date-picker__input {
  display: flex;
  position: relative;
  width: 100%;
  padding: 1.5rem;
  border: 1px solid #000000;
  align-items: center;
  margin-bottom: 1rem;
}

.mat-datepicker-content-container {
  padding-bottom: 1rem;
  padding-bottom: 1rem;
}

.mat-calendar-content .mat-calendar-body-label.ng-star-inserted {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.iti {
  display: block;
  margin-bottom: 1rem;
}

.iti input {
  display: block;
  width: 100%;
  font-family: inherit;
  font-size: 0.9em;
  border: 0;
  padding-left: 0.5rem !important;
}
.iti input:focus {
  outline: none;
}

.separate-dial-code {
  display: flex;
  width: 100%;
  padding: 1.5rem;
  border: 1px solid #000000;
}

.iti__flag-container {
  position: relative;
}

.iti__selected-flag {
  width: auto !important;
}

.selected-dial-code {
  font-size: 0.9em;
  line-height: 1rem;
}

.modal {
  background-color: rgba(0, 0, 0, 0.38);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  padding: 0 16px;
  position: fixed;
  right: 0;
  top: 0;
  text-align: center;
  z-index: 100;
  opacity: 1;
}

.modal-open {
  overflow: hidden;
}

.modal-content {
  background-color: white;
  border-radius: 4px;
  margin: 16px auto;
  margin-top: 20rem;
  max-width: 580px;
  position: relative;
  transition: opacity 400ms ease-in-out;
  width: 100%;
  will-change: opacity;
  text-align: left;
}

.modal-content-size-m {
  max-width: 992px;
}

.modal-content-size-l {
  max-width: 1200px;
}

.modal-footer {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-evenly;
  padding: 1rem;
  border-top: 1px solid #cecece;
}

.modal-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  border-bottom: 1px solid #cecece;
}

.modal-body {
  padding: 16px;
}

.modal-entry {
  height: 20rem;
  width: 100%;
  font: inherit;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.12);
}

.products-notifications__switch .switch-button-container {
  margin-bottom: 2rem;
}

.save-password-button {
  margin-left: 0;
}

.children-tab-lines-other-than-first-faded > :not(:first-child) > .night-report-tab-line {
  border-bottom: 1.5px dashed #444444;
  color: #444444;
}

.children-tab-lines-other-than-first-faded > *:last-child > .night-report-tab-line {
  border-bottom: 0px;
}

.introjs-tooltip.customTooltip {
  min-width: 40rem;
  max-width: 40rem;
  border-radius: 0px;
}

.customTooltip .introjs-tooltip-title {
  color: #04073F;
  font-family: "MaisonNeue Book", Arial, sans-serif;
  font-size: 1.2em;
}

.customTooltip .introjs-tooltiptext {
  font-family: "MaisonNeue Book", Arial, sans-serif;
}

.customTooltip .introjs-button:focus,
.customTooltip .introjs-button {
  font-family: "MaisonNeue Book", Arial, sans-serif;
  background-color: #04073F;
  border: 1px solid #04073F;
  color: #FFFFFF;
  font-size: 0.8em;
  text-align: center;
  transition: background-color 0.2s ease-out;
  text-shadow: none;
  box-shadow: none;
  border-radius: 0px;
}

.customTooltip .introjs-button:active,
.customTooltip .introjs-button:hover {
  background-color: #323296;
  color: #FFFFFF;
  cursor: pointer;
  border: 1px solid #04073F;
}

.customTooltip .introjs-tooltiptext b {
  font-weight: bold;
}

.customTooltip .introjs-tooltiptext div.space {
  display: block;
  width: 100%;
  height: 0.5em;
}

.customTooltip .introjs-skipbutton {
  border: none;
}

.customTooltip .introjs-dontShowAgain label {
  font-family: "MaisonNeue Book", Arial, sans-serif;
  font-size: 0.7em;
  color: black;
  vertical-align: middle;
  display: none;
}

.customTooltip .introjs-dontShowAgain {
  padding-bottom: 20px;
}

.customTooltip .introjs-dontShowAgain input {
  vertical-align: middle;
  width: 1em;
  height: 1em;
  border-color: black;
  display: none;
}

.customTooltip .introjs-tooltipbuttons {
  border: none;
  padding-left: 20px;
  padding-right: 20px;
}

.highlighted {
  border: 3px solid #FF3C46;
  border-radius: 0.5rem;
  padding: 2px;
  transition: border-color 0.8s ease-out;
}

.not-highlighted {
  border: 3px solid rgba(0, 0, 0, 0);
  border-radius: 0.5rem;
  padding: 2px;
  transition: border-color 0.8s ease-out;
}

.sorting-arrow {
  margin-left: 8px;
  height: 0.6rem;
}
.sorting-arrow-up {
  transform: rotate(180deg);
}

.image_viewer-container {
  display: flex;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
}
.image_viewer-container .image_viewer_image {
  margin: auto;
  display: block;
  padding: 1rem;
  max-width: 75%;
  max-height: 75%;
  background-color: #FFFFFF;
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}
.image_viewer-container .image_viewer-close_button {
  position: absolute;
  top: 4rem;
  right: 4rem;
}

.signature {
  height: 4rem;
  object-fit: contain;
}

.signature--small {
  height: 2.5rem;
  transition: all 0.2s ease-in-out;
}

.dropper-container {
  width: 100%;
  border: 1px dashed #04073F;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem;
  gap: 0.5rem;
}
.dropper-container .dropper-container__upload-img {
  width: 3rem;
  margin-bottom: 0.5rem;
}
.dropper-container .dropper-container__browse-button {
  min-width: 0;
}

.new_signature-container {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.img_check {
  height: 32px;
  vertical-align: middle;
  filter: grayscale(1);
}