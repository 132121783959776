@-webkit-keyframes zoom
    from
        -webkit-transform: scale(0)
    to
        -webkit-transform: scale(1)

@keyframes zoom
    from
        transform: scale(0)
    to
        transform: scale(1)
